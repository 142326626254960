import styled, {css} from 'styled-components';

export const Section  = styled.section`
    display: flex;
    background-color: white;
`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: center;
    z-index: 1;
    width: 50rem;
    gap: 20px;
    margin: 12rem 6rem 4rem 6rem;
`

export const Title = styled.h2(
   ({theme}) => css`
       color: ${theme.colors.primary[0]};
       font-family: ${theme.typography.fonts.secondary};
       font-size: ${theme.typography.sizes.extraExtraLarge};
       font-weight: ${theme.typography.weights.medium};
   `
);

export const ImageContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    justify-content: space-evenly;

    @media (max-width: 480px) {
        gap: 20px;
    }
`

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const StyledImageCircle = styled.div`
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdfdef;
    border-radius: 50%;
`;

export const StyledImage = styled.img`
    width: 100%;
    cursor:pointer;
    max-width: 150px;
`;