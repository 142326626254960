import {ImageContainer, ImageWrapper, Section, StyledImage, StyledImageCircle, Title, Wrapper} from "./Partners.styled";
import Ceamless from '../../../static/partners/ceamless.png';
import Migrapreneur from '../../../static/partners/migrapreneur.png';
import Disconnect from '../../../static/partners/diconnect.png';
import React from 'react';

interface ImageInterface {
   company: string;
   src: string;
   url: string;
   filterStyle?: React.CSSProperties;
}

const images: ImageInterface[] = [
   {
      company: 'Ceamless Event',
      src: Ceamless,
      url: 'https://www.ceamless.events/',
      filterStyle: {
         filter: 'grayscale(100%) brightness(0%) contrast(100%)',
      },
   },
   {
      company: 'Migrapreneur',
      src: Migrapreneur,
      url: 'https://migrapreneur.org/',
      filterStyle: {
         filter: 'grayscale(100%) brightness(0%) contrast(100%)',
      },
   },
   {
      company: 'Disconnect',
      src: Disconnect,
      url: 'https://www.instagram.com/_disconnect.to.connect_/',
      filterStyle: {
         width: '100%',
         transform: 'scale(1.5)',
         objectFit: 'cover',
         filter: 'grayscale(100%) brightness(0%) contrast(100%)',
      },
   },

];

const handleImageClick = (url: string) => {
   window.open(url, '_blank');
};

export const Partners = () => (
   <Section>
      <Wrapper>
         <Title>OUR PARTNERS</Title>
         <ImageContainer>
            {images.map((image, index) => (
               image.company === 'Migrapreneur' ? (
                  <ImageWrapper>

                     <StyledImageCircle key={index}>
                        <StyledImage
                           src={image.src}
                           style={image.filterStyle}
                           alt={`${image.company} Logo`}
                           onClick={() => handleImageClick(image.url)}
                        />
                     </StyledImageCircle>
                  </ImageWrapper>
               ) : (
                  <ImageWrapper>
                     <StyledImage
                        key={index}
                        src={image.src}
                        style={image.filterStyle}
                        alt={`${image.company} Logo`}
                        onClick={() => handleImageClick(image.url)}
                     />
                  </ImageWrapper>

               )
            ))}
         </ImageContainer>
      </Wrapper>
   </Section>
);
